import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { keycloakAuthURL, operatorCET } from './constants';

const encodeURIQuery = (params: any) => new URLSearchParams(params).toString();

const SERVER_URL = process.env.REACT_APP_REST_API_URL;

export const fetchAuthToken = async (username: string, password: string) => {
  try {
    let data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username,
      password,
      grant_type: 'password',
    }

    let response = await fetch(keycloakAuthURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeURIQuery(data)
    }).then(response => response.json())

    return response;
  } catch (error) {
    return false
  }
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: async (headers, { getState }: any) => {
      headers.set('Content-Type', 'application/json');

      const token = getState().auth?.accessToken;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    }
  }),
  tagTypes: ['Plans', 'Operators'],
  endpoints: (builder) => ({
    getPricePlans: builder.query({
      query: ({ operatorCode, group }) => ({
        url: `stripe-priceplan?operatorCode=${operatorCode}&group=${group}`,
        method: 'GET',
      }),
      providesTags: ['Plans'],
    }),
    getPersistenceOperators: builder.query({
      query: ({ token }) => ({
        url: operatorCET(),
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }),
      providesTags: ['Operators'],
    })
  }),
});

export const {
  useGetPricePlansQuery,
  useLazyGetPersistenceOperatorsQuery,
} = api;