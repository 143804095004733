import { Container } from '@mui/material';
import Logo from '../../../components/Logo/Logo';
import './UnikbaseAppIllustration.scss';

const UnikbaseAppIllustration = ({ variant = '' }) => {
	let source = require('../../../assets/image-artboard-phone.png');
	if (variant) {
		source = require(`../../../assets/image-artboard-phone-${variant}.png`);
	}

	return (
		<div className="illustration__wrapper">
			<div className="illustration unikbase-phone-app">
				<img src={source} alt="Unikbase" />
			</div>
		</div>
	)
}

export default UnikbaseAppIllustration;