import { useEffect, useState, useMemo } from "react";
import { Typography, Container } from "@mui/material";
import { toast } from 'react-toastify';
import { t } from "i18next";

import { getLang } from "../../helper/utils";
import { planFeaturesColumnsARS, pricing_plans_ARS } from "../../config/product";

import useHttp from "../../hooks/use-Http";
import PricingTableColumn from "./PricingTableColumn";
import { Operators } from "../../helper/constants";

import "./LandingPage_ARS.scss";
import { useLocation, useSearchParams } from "react-router-dom";

import Tracking from "../../components/Tracking/Tracking";
import UnikbaseAppIllustration from "./UnikbaseAppIllustration";
import Video from "./Video";
import Page from "../../layout/Page";
import EmailRequiredModal, { computeEmailHash } from "../../components/EmailRequiredModal";
import GoogleImageCustomSearch from "../../components/GoogleImageCustomSearch";
import { useGetPricePlansQuery } from "../../api/api";
import { REDIRECT_URL, STRIPE_PAYMENT_URL, TPK_SIGNUP_URL, digestMessage, hexToBinary, useUrlParam } from "./LandingPage";


const OPERATOR_CODE = 'ARS';

export const arsWordingCorrection = (text) => {
  return text.replace(/(collectible(s)?)|(object(s)?)/g, 'artwork(s)')
  .replace(/objets?\sde\svaleur/g, 'oeuvres')
}

const LandingPageContent = () => {
  const location = useLocation();
  const [status, setStatus] = useState("idle");
  const [pricingColumns, setPricingColumns] = useState([]);
  const [pricePlans, setPricePlans] = useState({});
  const [testingABId, setABTestingId] = useState('');
  const [selectedOffer, setSelectedOffer] = useState('');
  const [redirectURL, setRedirectURL] = useState('');

  let lang = useUrlParam("lang");
  if (!lang) lang = navigator.language || navigator.userLanguage;
  //Converting tpk_id to get price from table
  let tpk_jwt = useUrlParam("token"); // TPK access token to check original order
  let tpk_id = useUrlParam("tpk_id"); // order id
  let _image = useUrlParam("image");
  let value = useUrlParam('value', 0);
  let currency = useUrlParam("currency", 'EUR');
  let token_name = useUrlParam("name");
  let token_description = useUrlParam("description");
  let [email, setEmail] = useState(useUrlParam('email', ''));
  let emailHash = useUrlParam("email_hash", null);
  const [image, setImage] = useState(_image);

  if (!token_name || token_name.length === 0) {
    token_name = !token_description ? 'N/A' : token_description.substring(0, 25);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    document.title = 'Artsper x Unikbase';
    document.querySelector("link[rel='icon']").href = "favicon-ars.png";
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 600);
    });
  }, [])

  const operator = useMemo(() => {
    if (/\/ars\/?/.test(location.pathname)) {
      return Operators.ARS;
    } else {
      return Operators.TPK;
    }
  }, [location]);

  const language = getLang(lang); //Replace it later by func
  const pageTexts = language.pages.landing_page;

  const { data: planResp } = useGetPricePlansQuery({ operatorCode: OPERATOR_CODE, group: testingABId });

  const stripePlans = useMemo(() => {
    if (!planResp?.result || !Array.isArray(planResp?.result)) return [];
    const plans = [...planResp?.result];
    return plans.sort((a, b) => a.unitAmount - b.unitAmount) || []
  }, [planResp]);

  let pricingTables = useMemo(() => pageTexts.pricing_table || {}, [pageTexts]);
  const footerImages = useMemo(() => ({
    Free: '',
    Standard: '',// require("../../assets/standard_fig.jpg"),
    Plus: '',//require("../../assets/plus_fig.jpg"),
  }), []);

  const {
    sendRequest: sendPostRequest,
    setIsLoading: setIsLoadingPost,
    setHttpError: setHttpErrorPost,
  } = useHttp({
    url: STRIPE_PAYMENT_URL,
    method: "POST",
  });
  // const {isLoading:isLoadingGet,hasError:httpGetError,sendRequest:sendGetRequest,setIsLoading:setIsLoadingGet,setHttpError:setHttpErrorGet} = useHttp({url:'https://checkout.stripe.com',method: 'GET'})

  useEffect(() => {
    if (!tpk_id) {
      window.location.replace(REDIRECT_URL);
      return;
    }
  }, [tpk_id]);

  useEffect(() => {
    if (emailHash && email) {
      const validateEmailHash = async () => {
        const _hash = await computeEmailHash(`${email}`);
        if (emailHash !== _hash) {
          setEmail(''); // ask for email if initial email is incorrect
        }
      }
      validateEmailHash()
    }
  }, [emailHash])

  useEffect(() => {
    if (testingABId && planResp?.status === 'fail') {
      toast.error(t('pages.landing_page.error_fetching_plans'));
    }
  }, [planResp])

  useEffect(() => {
    if (selectedOffer) {
      var button = document.getElementById(`gtm-button-${operator.code}-${selectedOffer}-${testingABId}`);
      if (button) {
        button.click();
      }
    }
  }, [selectedOffer, testingABId, operator]);

  useEffect(() => {
    if (redirectURL && selectedOffer) {
      window.location.replace(redirectURL)
    }
  }, [redirectURL, selectedOffer]);

  useEffect(() => {
    const planIndex = async (input) => {
      let hash = await digestMessage(input);
      let binaryString = hexToBinary(hash);

      const size = 8; // number of bits
      const numOfPlans = 3;

      const num = parseInt(binaryString.substring(binaryString.length - size), 2);
      const max = Math.pow(2, size);

      const division = Math.floor(num / (max / numOfPlans)); // A, B, C 33% each
      return division > 2 ? 2 : division;
    };

    const updatePricingColumns = async () => {
      if (email && email !== null && email !== "Email@mail.com") {
        let plan = await planIndex(email);
        setABTestingId(Object.keys(pricing_plans_ARS)[plan]);
        setPricePlans(pricing_plans_ARS[Object.keys(pricing_plans_ARS)[plan]]);
        setPricingColumns(planFeaturesColumnsARS);
      }
    }
    updatePricingColumns();
  }, [email, pricingTables, value]);

  const submitHandler = async (data) => {
    const _hash = await computeEmailHash(`${email}`);
    if (!email || !emailHash) {
      return toast.error(t('pages.landing_page.email_is_missing'))
    } else if (emailHash !== _hash) {
      return toast.error(t('pages.landing_page.email_hash_incorrect'))
    }

    let payload = {
      email: email,
      tpk_id: tpk_id.startsWith(`${OPERATOR_CODE}_`) ? tpk_id : `${OPERATOR_CODE}_` + tpk_id, // Adding the "TPK_" prefix to the tpk_id to get the correct stripe config in backend
      price_id: data.price_id,
      value: "" + value, //price_item
      image: image,
      description: token_description,
      name: token_name,
      commercialOfferCode: data?.code,
      emailHash,
      currency,
      abPlanId: testingABId,
      tpk_token: tpk_jwt,
    };
    setStatus("loading");
    if (data.value <= 0) {
      // Send to collector page
      const signupLink =
        TPK_SIGNUP_URL +
        `?tpk_id=${payload.tpk_id}&email=${email}&emailHash=${emailHash}&name=${encodeURIComponent(token_name)}&value=${value}&currency=${currency}&description=${encodeURIComponent(token_description)}&image=${encodeURIComponent(image)}&plan=${data.value || "free"
        }&commercialOfferCode=${data?.code}&abPlanId=${testingABId}&tpk_token=${encodeURIComponent(tpk_jwt)}`;

      setSelectedOffer(payload.commercialOfferCode);
      setRedirectURL(signupLink);
    } else {
      // send to stripe
      sendPostRequest(payload)
        .catch((error) => {
          setIsLoadingPost(false);
          setHttpErrorPost(error.message);
        })
        .then((resp) => {
          if (resp.status === 'fail') {
            toast.error(resp.result);
            setStatus('error')
          } else if (resp) {
            const url = resp;
            let session_id = url.split("https://checkout.stripe.com/c/pay/").join("");
            session_id = session_id.split("#")[0];

            localStorage.setItem("session_id", session_id);
            localStorage.setItem(
              `_tpk_request_${session_id}`,
              JSON.stringify({
                ...payload,
                token_name,
                token_description,
                image,
              })
            );
            setSelectedOffer(payload.commercialOfferCode);
            setRedirectURL(url)
          }
        });
    }
  };

  return (
    <Page status={status}>
      {!!tpk_id && (
        <Container maxWidth="lg" component="section" className="section">
          <Tracking order={tpk_id} />

          <div className="row">
            <div className="unikbase-data col col--1" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div className="row">
                <div id="product" className="col col--1-3 bg--white">
                  <div className="product">
                    <div className="product__thumbnail thumbnail">
                      <div
                        className="thumbnail__wrap"
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      >
                        {image && (
                          <img
                            style={{
                              objectFit: 'contain'
                            }}
                            src={image}
                            alt={token_name || ""}
                          ></img>
                        )}
                      </div>
                    </div>
                    <h3 className="product__title">{token_name || "Object name"}</h3>
                    <Typography className="product_description" component="div" variant="body1">
                      {token_description || ""}
                    </Typography>
                  </div>
                </div>

                <div
                  id="about-tpk"
                  className="col col--2-3"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row content">
                    <div className="col col--2-3">
                      <Typography component="h3" variant="body1">
                        {arsWordingCorrection(t("pages.landing_page.title_ARS", { operator: operator.name }))}
                      </Typography>

                      <Typography component="h4" variant="body1">
                        {t('pages.landing_page.sub_title_ARS')}
                      </Typography>
                    </div>
                  </div>
                  <UnikbaseAppIllustration variant="ars"/>
                </div>
              </div>

              <div id="pricing-plans-ars" className="row">
                <div className="col col--1">
                  <div className="row">
                    {!!pricingColumns &&
                      pricingColumns.map((column, idx) => {
                        const stripePlan = stripePlans[idx]
                        let pricePlan = { ...pricePlans[column.title] };
                        if (stripePlan) {
                          pricePlan.price = stripePlan.unitAmount / 100;
                          pricePlan.currency = stripePlan.currency
                          pricePlan.price_id = stripePlan.metadata.priceId
                          pricePlan.hosting = stripePlan.type !== 'recurring' ? 1000 : stripePlan.recurring.intervalCount * (stripePlan.recurring.interval === 'month' ? 1 : 12)
                        } else {
                          return null;
                        }

                        return (
                          <PricingTableColumn
                            key={`offer-${idx}`}
                            id={`column-${idx}`}
                            pricePlan={pricePlan}
                            planPeriod={false}
                            image={image}
                            column={column}
                            wordingCorrection={arsWordingCorrection}
                            callback={submitHandler}
                            imageType={idx >= 1 ? "3D" : ""}
                            options={pricingTables.options_ARS[column.title.toUpperCase()]}
                            footerImage={footerImages[column.title]}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}

      <Container maxWidth="lg" component="section" className="section" id="about-digital-passport">
        <div className="row">
          <div className="col col--1-3 bg--gray">
            <Video />
          </div>
          <div className="col bg--black col--2-3">
            <Typography component="h3" variant="body1" color="white">
              {t('pages.landing_page.what_is_digital_passport')}
            </Typography>

            <Typography
              component="div"
              variant="body1"
              color="white"
              dangerouslySetInnerHTML={{ __html: t('pages.landing_page.what_is_digital_passport_paragraph', { operator: operator.name }) }}
            ></Typography>
          </div>
        </div>
      </Container>

      <Container maxWidth="lg" component="section" className="section" id="benefits">
        <div className="row">
          <div className="col col--2-3 bg--gray">
            <Typography component="h3" varaint="body1">
              {t('pages.landing_page.benefits')}
            </Typography>
            <ul className="list">
              {!!pageTexts.benefit_items_ARS &&
                pageTexts.benefit_items_ARS.map((i, idx) => (
                  <li key={`benefit-item-key-${idx}`} className="item">
                    <span className="item__title">{i.title}</span>
                    <span className="item__short">{i.short}</span>
                    {arsWordingCorrection(i.content).replace(/\{\{operator\}\}/g, operator.name)}
                  </li>
                ))}
            </ul>
          </div>
          {!isMobile && (
            <div
              className="col col--1-3 benefit-cover-image"
              style={{
                backgroundImage: `url(${require("../../assets/benefit-bg-ars.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          )}
        </div>
      </Container>
      {!email && <EmailRequiredModal open={!email} onUpdate={setEmail} hash={emailHash} />}
      <GoogleImageCustomSearch
        open={!!email && !image}
        onSetImage={setImage}
        query={token_description}
      />
      <button id={`gtm-button-${operator.code}-${selectedOffer}-${testingABId}`} type="button" style={{ display: 'none' }}></button>
    </Page>
  );
};

export default LandingPageContent;
