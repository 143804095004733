import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { t } from 'i18next';
import LoaderBar from "../LoaderBar";

const GOOGLE_CUSTOM_SEARCH = 'https://www.googleapis.com/customsearch/v1'
const LIMIT = 3 // number of results in range 1-10
const API_KEY = process.env.REACT_APP_GOOGLE_SEARCH_API_KEY
const SEARCH_ENGINE_ID = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID

export const IMAGE_SEARCH_URL = `${GOOGLE_CUSTOM_SEARCH}?key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&searchType=image&num=${LIMIT}&q=`;

interface IGoogleImageCustomSearchProps {
  open: boolean;
  query: string;
  onSetImage: (image: string) => void;
}

const GoogleImageCustomSearch = ({ open, query, onSetImage }: IGoogleImageCustomSearchProps) => {
  const [gImage, setGImage] = useState<any>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [openImageSuggestion, showImageSuggestion] = useState(false);
  const [foundImages, setFoundImages] = useState<any[]>([]);


  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  const onImageError = () => {
    const images = [...foundImages]
    images.splice(0, 1) // remove failed image
    if (images.length > 0) {
      setGImage(images[0])
      setFoundImages(images)
    } else {
      showImageSuggestion(false)
    }
  }

  useEffect(() => {
    if (query) {
      fetch(IMAGE_SEARCH_URL + encodeURIComponent(query))
        .then((response) => response.json())
        .then((data) => {
          if (data && data.items && Array.isArray(data.items)) {
            setFoundImages(data.items)
            setGImage(data.items[0])
          }
        })
        .catch((error) => console.error('Error:', error))
    }
  }, [query])

  useEffect(() => {
    if (!!gImage && open) {
      showImageSuggestion(true)
    }
  }, [gImage, open])

  return (
    <Dialog
      open={openImageSuggestion}
    >
      <DialogTitle>{t('pages.landing_page.image_suggestion')}</DialogTitle>
      <DialogContent>
        <Typography>{t('pages.landing_page.image_suggestion_confirm')}</Typography>
        <Box mt={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!imageLoaded && <LoaderBar size={80} />}
          {gImage && <img style={{ objectFit: 'contain', maxHeight: '50vh' }} src={gImage.link} alt={gImage.link} width={imageLoaded ? 500 : 0} onLoad={onImageLoaded} onError={onImageError} />}
          {gImage && imageLoaded && (
            <a href={gImage.image.contextLink} target="_blank" rel="noreferrer">{gImage.image.contextLink.substring(0, 60)}...</a>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {imageLoaded && (
          <Grid container spacing={2} style={{ padding: 15 }}>
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%' }}>
              <Button variant="contained"
                color='primary'
                style={{ width: '100%' }}
                onClick={() => {
                  showImageSuggestion(false);
                  onSetImage && onSetImage(gImage.link);
                }}>
                {t('texts.yes')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ width: '50%' }}>
              <Button variant="outlined"
                color='primary'
                style={{ width: '100%' }}
                onClick={() => showImageSuggestion(false)}>
                {t('texts.no')}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default GoogleImageCustomSearch
