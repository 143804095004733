import React, { useCallback, useEffect, useMemo } from "react";
// import {BrowserRouter as Router, Link, Routes, Route} from 'react-router-dom'

import { SuccessPage, CancelPage, LandingPageContent as LandingPage, LandingPageARS } from "./page" 

import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { Operators } from "./helper/constants";

const routes = [{
    key: 'main',
    path: "/",
    element: <LandingPage />,
  },
  {
    key: 'ars',
    path: "/ars",
    element: <LandingPageARS />,
  },
  {
    key: 'success',
    path: "/success",
    element: <SuccessPage />,
  },
  {
    key: 'cancel',
    path: "/cancel",
    element: <CancelPage />,
  }
];

function App() {
  const location = useLocation();
	const [searchParams] = useSearchParams();
	const urlParams = new URLSearchParams(window.location.search);

  let lang = urlParams.get('lang');
  if ( !lang ) lang = searchParams.get('lang');
  if ( !lang ) lang = navigator.language || navigator.userLanguage; 

  window.__current_language = lang;

  const operator = useMemo(() => {
    if (/\/ars\/?/.test(location.pathname)) {
      return Operators.ARS;
    } else {
      return Operators.TPK;
    }
  }, [location]);

  // Google Tags
  useEffect(() => {
    const script = document.createElement('script');
    script.text = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-ND4TWKV7');
		`;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement('noscript');
    script.text = `
		<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-ND4TWKV7"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`;
    document.body.insertBefore(script, document.body.firstChild);
  }, []);
  // End Google Tags

  return (
    <div className={`site site__${operator.code}`} >
        <Routes>
          {routes.map(item => {
            if (item.childs) {
              return (
                <Route key={`route-${item.path}`} element={item.element} path={item.path} >
                  {item.childs.map(child => <Route key={`route-${item.path}${child}`} path={child} element={null} />)}
                </Route>
              )
            } 
            return <Route key={`route-${item.path}`} element={item.element} path={item.path} />
          })}
        </Routes>
    </div>
  );
}


export default App;
