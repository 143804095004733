import { useEffect } from "react";
import { useLocation } from "react-router";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

const theme = createTheme({
	breakpoints: {
		values: {
			lg: 1366
		}
	},
	typography: {
		fontFamily: [
			'Nunito Sans'
		]
	},
	status: {
		danger: "#e53e3e",
	},
	palette: {
		primary: {
			main: "#EA5123",
			darker: "#053e85",
		},
		neutral: {
			main: "#64748B",
			contrastText: "#fff",
		},
	},
	invalid: {
		backgroundColor: "#fff",
		transition: "300ms",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		"& p": {
			color: "rgb(218, 63, 63)",
			marginTop: "0",
			transition: "400ms",
			fontSize: "0.5rem",
			textAlign: "center",
			marginRight: "3rem",
		},
	},
});

const Page = (props) => {
	const { status = 'idle', children } = props
	const location = useLocation();

	useEffect(() => {
		if (/\/ars\/?/.test(location.pathname)) {
			document.body.className = 'page__ARS';
			theme.typography.fontFamily = 'Apercu';
			theme.palette.primary.main = "#000";
		}
	}, [location]);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{!!(status === 'loading') && <div className="loading"></div>}
			<Header />
			{children}
			<Footer />
		</ThemeProvider>
	)
}

export default Page;