import { Container, Grid, Box } from "@mui/material";
import React from "react";
import './Footer.scss'
import Instagram from "../components/Instagram/Instagram";
import Logo from "../components/Logo/Logo";
import { styled } from '@mui/material/styles';
import { t } from "i18next";

const Footer = (props) => {

  return (
    <>
      <div id="footer" className="site__footer section--bg-white">
        <Container
					maxWidth="lg" 
					component="div"
					sx={{ flexGrow: 1 }}
				>
					

          <Grid container className="footer--bottom">
            <div className="brand">
              <div className="site__logo">
                  <a href="index.html" title="UnikBase">
										<Logo textColor="#001c26" />
                  </a>
              </div>
            </div>

            <div className="socials">
                <ul className="list list--vertical flex--center flex--space-between">
                    <li><a target="_blank" rel="noreferrer" href="https://medium.com/@unikbase" title="Unikbase on medium"><span style={{ 
											maskImage: 'url(https://unikbase.com/assets/images/SVG/icon-medium.svg)',
											WebkitMaskImage: 'url(https://unikbase.com/assets/images/SVG/icon-medium.svg)'
										}}></span></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/unikbase/" title="Unikbase on Instagram"><span style={{ 
											maskImage: 'url(https://unikbase.com/assets/images/SVG/icon-instagram.svg)',
											WebkitMaskImage: 'url(https://unikbase.com/assets/images/SVG/icon-instagram.svg)'
										}}></span></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://twitter.com/unikbase" title="Unikbase on twitter"><span style={{ 
											maskImage: 'url(https://unikbase.com/assets/images/SVG/icon-twitter.svg)',
											WebkitMaskImage: 'url(https://unikbase.com/assets/images/SVG/icon-twitter.svg)'
										}}></span></a></li>
                    <li><a target="_blank" rel="noreferrer" href=" https://www.linkedin.com/company/unikbase/" title="Unikbase on linkedin"><span style={{ 
											maskImage: 'url(https://unikbase.com/assets/images/SVG/icon-linkedin.svg)',
											WebkitMaskImage: 'url(https://unikbase.com/assets/images/SVG/icon-linkedin.svg)'
										}}></span></a></li>
                </ul>
            </div>

						<div className="others">
							<ul className="menu menu-term list">
									<li><a href="https://unikbase.com/privacy-policy.html" title="Privacy Policy">{t('texts.privacy_policy')}</a></li>
									<li><a href="https://unikbase.com/terms-conditions.html" title="Terms & Conditions">{t('texts.terms_and_conditions')}</a></li>
                <li><a href="https://unikbase.notion.site/Q-A-Unikbase-Artsper-0499ea981ec247a3b8d949f5336244f5" title="Q&A">{t('texts.frequently_asked_questions')}</a></li>
							</ul>
            	<div className="copy-right">© unikbase, 2024</div>
						</div>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Footer;