import React, { useEffect, useState } from "react";

import { 
  Grid, 
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography
} from "@mui/material";


import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import ImageHandler from "./ImageHandler";
import { __, getLang } from "../../helper/utils";
import { useUrlParam } from "./LandingPage";
import { currencySymbols } from "../../helper/constants";
import { t } from "i18next";

const PricingTableColumn = (props) => {
	const { id, column, image, options = [], imageType = 'default', pricePlan, planPeriod=false, footerImage, wordingCorrection, callback } = props;
	const email = useUrlParam('email', 'email@mail.com');
	const planPrice = pricePlan?.price;

	const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setIsMobile(window.innerWidth < 600);
		});
	}, [])

  const submitHandler = (e) => {
    e.preventDefault();

    callback({
      email: email,
      price_id: `${pricePlan?.price_id}`,
      value: `${planPrice}`.includes('__PRICE__') ? `${planPrice}`.split('\n')[0] : planPrice,
			code: pricePlan?.code,
			currency: pricePlan?.currency
    });
	}
	
	const hosting = pricePlan.hosting === 1000 ? t('texts.lifetime') : pricePlan.hosting < 12
											? `${pricePlan.hosting} ${pricePlan.hosting > 1 ? t('texts.months') : t('texts.month')}`
											: `${pricePlan.hosting / 12} ${(pricePlan.hosting / 12) > 1 ? t('texts.years') : t('texts.year')}`
  
  return (
    // Enterprise card is full width at sm breakpoint
    <Grid
      item
      key={column.title}
			xs={12}
      sm={6}
      md={6}
      className="pricing_table__column col"
    >
      <Card>
        <CardContent>
          <Box className="column__title">
            <div className="wrap">
							<Typography className="option_title" component="h5" variant="body2">
								{t(`pages.landing_page.pricing_table.options_title.${pricePlan?.code}`) || ''}
							</Typography>
              <Typography component="h3">
								{column.title}
							</Typography>
							{!!pricePlan && <>
								<Typography component="h6" textAlign="center">
									{`${planPrice}`.includes('__PRICE__')
										? `${planPrice}`.replace('__PRICE__', t('texts.of_object_price'))
											.split('\n')
											.map((e, idx) => {
												const first = idx === 0;
												return (
													<Typography
														component={first ? "h6" : "p"}
														fontSize={first ? 'inherit' : 18}
													>{first && <span className="currency">{currencySymbols[pricePlan?.currency?.toUpperCase()]}</span>}{e}</Typography>
												)
											})
										: <>
											<span className="currency">{currencySymbols[pricePlan?.currency?.toUpperCase()]}</span>
											{planPrice > 0 ? planPrice.toLocaleString() : planPrice}{`${planPrice}`.includes('.') ? '0' : ''}
										</>
									}
								</Typography>
							</>
							}

              {false && imageType === '3D' &&
                <div className="_3d-wrap">
                  <ImageHandler image={image} className="image--3d" />
                  <div className="space3d">
                    <div className="_3dbox">
                      <div className="_3dface _3dface--front">
                        <img src={image} alt="product" />
                      </div>
                      <div className="_3dface _3dface--top"></div>
                      <div className="_3dface _3dface--left"></div>
                    </div>
                  </div>
                </div>
              }
              {false &&  imageType !== '3D' &&
                <ImageHandler image={image} className="image--3d" />
              } 

							<div className="column__actions">
								<form action="/" onSubmit={submitHandler} style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center"
								}} className={planPrice<=0?'have-email':''}>
									{planPrice <= 0 && 
										<input 
											type="hidden" 
											id={`field-email-${id}`}
											label={__('Your Email')}
											variant="standard"
											style={{ fontSize: "1rem" }}
											sx={{
												width: "100%",
												mt: "1.25em"
											}}
											value={email}
											required
										/>
									}
									<Button
										id={`button-submit-${id}`}
										className="column__button"
										type="submit"
										fullWidth
										variant="outlined"
									>
										{t('pages.landing_page.pricing_table.select_button') || __(`Select Plan`)}
									</Button>
								</form>
							</div>
            </div>
          </Box>
          <div className="column__description">
						{!!column?.options && [...column.options, planPeriod ? 2 : false].map((line, idx) => {
							let available = Array.isArray(line) ? !!line[0] : !!line;
							const note = Array.isArray(line) && line[1] ? line[1] : '';
							if (!available) return null;
							

							const render = () => (
								(
								<div key={`option-${idx}`} className={`option option--${available?'tick':'cross'}`}>
									<div
										key={`option-item-${idx}`}
										className={available?'tick': 'cross'}
									>
										<span className="icon">
											{available ? <CheckSharpIcon color="default" /> : <CloseSharpIcon color="primary" />}
										</span>
										{!!note && <span className="note">{note}</span>}
									</div>
										<Box sx={{ textAlign: "center", pl: 1, pr: 1 }} className="option__text">{
											line === 2
												? `${hosting} ${t('texts.hosting')}`
												: typeof wordingCorrection === 'function'
												? wordingCorrection(options[idx - (!!line ? 0 : 1)])
												: options[idx - (!!line ? 0 : 1)]
									}</Box>
								</div>
							)
							)
							
							return render()
						})}
          </div>
					<div className="column__footer" style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'center' }}>
						{!!footerImage && <img src={footerImage} alt="figure" style={{ width: '94%', maxWidth: 290, height: 'auto' }} />}
					</div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PricingTableColumn;