import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
// import Link from '@mui/material/Link';
import Container from "@mui/material/Container";

import useHttp from "../../hooks/use-Http";
import Page from "../../layout/Page";
import { t } from "i18next";

const STRIPE_CANCEL_URL = process.env.REACT_APP_STRIPE_CANCEL_URL || "https://dev1.unikbase.dev/meveo/rest/cancelStripeSession";

const CancelPage = (props) => {

	let params = {};

	let ses_id = localStorage.getItem("session_id");
	if ( ses_id ) {
		params = localStorage.getItem(`_tpk_request_${ses_id}`);
		try {
			params = JSON.parse(params)
		} catch (e) {
			params = {}
		}
	}

	// Update return link
	let sub_title = t('pages.cancel_page.sub_title');
	if (params) {
		let path = params?.tpk_id?.includes('_') ? params.tpk_id.split('_')[0].toLowerCase() : '';
		sub_title = sub_title.replace(/href="\/?"/, `href="/${!path || path === 'tpk' ? '' : `#${path}`}?tpk_id=${params.tpk_id}&value=${params.value}&image=${encodeURIComponent(params.image)}${params.currency ? `&currency=${params.currency}` : ''}${params.email ? `&email=${encodeURIComponent(params.email)}` : ''}${params.emailHash ? `&email_hash=${params.emailHash}` :'' }${params.name ? `&name=${encodeURIComponent(params.name)}` : ''}${params.description ? `&description=${encodeURIComponent(params.description)}` : ''}"`);
	}

	const {
		isLoading: isLoading,
		hasError: httpError,
		sendRequest: sendRequest,
		setIsLoading: setIsLoading,
		setHttpError: setHttpError,
	} = useHttp({ url: `${STRIPE_CANCEL_URL}`, method: "POST" });

	useEffect(() => {
		if (ses_id) {
			sendRequest({
				"sessionId": ses_id
			})
				.catch((error) => {
					setIsLoading(false);
					setHttpError(error.message);
				})
				.then((url) => {
					console.log(url);
				});
			// console.log("CANCELED SUCCESSFULLY");
			//  localStorage.clear();
			ses_id = null;
		}
	}, ses_id);

	return (
		<Page>
			<Container
				maxWidth="lg"
				className="section bg--blue"
				component="section"
			>
				<div className="row">
					<div className="col col--1">

						<Typography
							component="h2"
							variant="h4"
							align="center"
							gutterBottom
							sx={{
								fontWeight: 500,
								mb: "2em",
							}}
						>
							{t('pages.cancel_page.title')}
						</Typography>
						<Typography
							align="center"
							variant="h4"
							sx={{ mb: "1em", fontWeight: 400 }}
						>
							<div className="" dangerouslySetInnerHTML={{__html: sub_title}}></div>
						</Typography>
					</div>
				</div>
			</Container>
		</Page>
	);
}

export default CancelPage;
