import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enDict from './en.json'
import frDict from './fr.json'
import esDict from './es.json'
import deDict from './de.json'

const resources = {
  en: { translation: enDict},
  fr: { translation: frDict },
  es: { translation: esDict },
  de: { translation: deDict },
};

export const languages = Object.keys(resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;