export const currencySymbols = {
  EUR: '€',
  USD: '$',
  GBP: '£',
}

export const Operators = {
  TPK: {
    code: 'TPK',
    homepage: 'https://www.thepackengers.com/',
    name: 'ThePackengers',
    logo: process.env.PUBLIC_URL+'/static/media/the-packengers-logo.e49b308ab6eb7e1357c0.svg'
  },
  ARS: {
    code: 'ARS',
    homepage: 'https://www.artsper.com/',
    name: 'Artsper',
    logo: '/logo-artsper-x-unikbase.png'
  }
}
