import { useState, useCallback, useEffect } from "react";
import lang_en from "../lang/en.json";
import lang_fr from "../lang/fr.json";
import lang_es from "../lang/es.json";
import lang_de from "../lang/de.json";

const utf8Encode = new TextEncoder();

export function getOfferId(tpk_id) {
	var enc = utf8Encode.encode(tpk_id);
	let total = 0;
	for (var i = 0; i < enc.length; i++) {
		total += enc[i];
	}
	return total % 3;
}

export function getLang(lang) {
	lang = lang || window.__current_language || "en";

	if (!!lang & (lang.indexOf("fr") === 0)) return lang_fr;
	// if (!!lang & (lang.indexOf("es") === 0)) return lang_es;
	// if (!!lang & (lang.indexOf("de") === 0)) return lang_de;
	return lang_en;
}

export function __(text) {
	const languages = getLang();
	if (typeof languages.texts[text] !== "undefined") {
		return languages.texts[text];
	}
	return text;
}

export const useHash = () => {
	const [hash, setHash] = useState(() => window.location.hash);

	const hashChangeHandler = useCallback(() => {
		setHash(window.location.hash);
	}, []);

	useEffect(() => {
		window.addEventListener("hashchange", hashChangeHandler);
		return () => {
			window.removeEventListener("hashchange", hashChangeHandler);
		};
	}, []);

	const updateHash = useCallback(
		(newHash) => {
			if (newHash !== hash) window.location.hash = newHash;
		},
		[hash]
	);

	return [hash, updateHash];
};
