import React from "react";
import './Logo.scss';

const Logo = (props) => {
  const { styles = {}, className = "", textColor = '#ffffff', shapeColor = '#e95224' } = props;

  return (
    <div style={styles} className={`unikbase__logo ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" id="logo_unikbase" data-name="Layer 1" viewBox="0 0 357.17 102.05">
        <g>
          <path className="cls-2" fill={textColor} d="m95.7,65.04c.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4.27.27.4.62.4,1.07v18.57c0,7.59,3.31,11.38,9.92,11.38,3.2,0,5.74-1.01,7.62-3.03s2.83-4.8,2.83-8.36v-18.57c0-.44.13-.8.4-1.07.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4s.4.62.4,1.07v31.69c0,.44-.13.8-.4,1.07-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4-.27-.27-.4-.62-.4-1.07v-3.13c-1.33,1.73-2.85,3.04-4.56,3.93-1.71.89-3.89,1.33-6.56,1.33-4.35,0-7.66-1.38-9.92-4.13-2.26-2.75-3.39-6.35-3.39-10.79v-18.91c0-.44.13-.8.4-1.07Z"/>
          <path className="cls-2" fill={textColor} d="m163.01,98.86c-.27.27-.62.4-1.06.4h-1.13c-.44,0-.8-.13-1.07-.4-.27-.27-.4-.62-.4-1.07v-18.58c0-3.59-.88-6.39-2.63-8.39-1.75-2-4.27-3-7.56-3s-5.75,1.01-7.66,3.03c-1.91,2.02-2.86,4.81-2.86,8.36v18.58c0,.44-.13.8-.4,1.07-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4s-.4-.62-.4-1.07v-31.69c0-.44.13-.8.4-1.07.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4s.4.62.4,1.07v3.13c1.33-1.73,2.85-3.04,4.56-3.93,1.71-.89,3.92-1.33,6.62-1.33,4.35,0,7.7,1.39,10.05,4.16,2.35,2.77,3.53,6.36,3.53,10.75v18.91c0,.44-.13.8-.4,1.07Z"/>
          <path className="cls-2" fill={textColor} d="m178.22,57.12c-.29.27-.66.4-1.1.4h-2.53c-.45,0-.8-.13-1.07-.4s-.4-.62-.4-1.07v-2.53c0-.44.13-.81.4-1.1.27-.29.62-.43,1.07-.43h2.53c.44,0,.81.15,1.1.43.29.29.43.66.43,1.1v2.53c0,.44-.15.8-.43,1.07Zm-.7,41.74c-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4s-.4-.62-.4-1.07v-31.69c0-.44.13-.8.4-1.07.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4s.4.62.4,1.07v31.69c0,.44-.13.8-.4,1.07Z"/>
          <path className="cls-2" fill={textColor} d="m213.87,96.6c.58.53.87.98.87,1.33,0,.4-.12.72-.37.97-.24.24-.57.37-.97.37h-1.07c-.67,0-1.15-.06-1.46-.17-.31-.11-.76-.43-1.33-.97l-16.71-14.85v14.51c0,.44-.13.8-.4,1.07-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4-.27-.27-.4-.62-.4-1.07v-44.34c0-.44.13-.8.4-1.07.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4.27.27.4.62.4,1.07v23.57l14.85-11.25c.71-.53,1.2-.85,1.46-.97.27-.11.71-.17,1.33-.17h1.27c.4,0,.72.12.97.37.24.24.37.57.37.97,0,.49-.29.93-.87,1.33l-16.84,12.78,18.51,16.51Z"/>
          <path className="cls-2" fill={textColor} d="m250.76,81.95c0,.8-.02,1.42-.07,1.86-.13,4.48-1.34,8.29-3.63,11.42-2.29,3.13-5.83,4.69-10.62,4.69s-8.61-1.75-11.18-5.26v3.13c0,.44-.13.8-.4,1.07-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4-.27-.27-.4-.62-.4-1.07v-44.34c0-.44.13-.8.4-1.07.27-.27.62-.4,1.07-.4h1.13c.44,0,.8.13,1.07.4.27.27.4.62.4,1.07v15.78c2.62-3.5,6.35-5.26,11.18-5.26s8.33,1.56,10.62,4.69c2.29,3.13,3.5,6.93,3.63,11.42.04.44.07,1.07.07,1.86Zm-6.82,10.69c1.66-2.29,2.56-5.23,2.7-8.82.04-.44.07-1.07.07-1.86,0-4.13-.8-7.51-2.4-10.15-1.6-2.64-4.35-3.96-8.26-3.96-2.35,0-4.33.56-5.93,1.66s-2.8,2.52-3.59,4.23c-.8,1.71-1.22,3.52-1.27,5.43l-.07,2.6.07,2.6c.13,3.15,1.09,5.89,2.86,8.22,1.77,2.33,4.42,3.49,7.92,3.49s6.22-1.14,7.89-3.43Z"/>
          <path className="cls-2" fill={textColor} d="m280.85,94.67c-.98,1.47-2.41,2.71-4.29,3.73-1.89,1.02-4.38,1.53-7.49,1.53-2.13,0-4.11-.44-5.93-1.33-1.82-.89-3.27-2.09-4.36-3.59-1.09-1.51-1.63-3.17-1.63-4.99,0-2.93,1.19-5.33,3.56-7.19,2.37-1.86,5.58-3.06,9.62-3.6l10.52-1.46v-2.33c0-2.44-.74-4.32-2.23-5.63-1.49-1.31-3.76-1.96-6.82-1.96-2.26,0-4.12.44-5.56,1.33-1.44.89-2.36,1.95-2.76,3.2-.18.53-.39.9-.63,1.1s-.57.3-.97.3h-.93c-.4,0-.74-.14-1.03-.43-.29-.29-.43-.63-.43-1.03,0-.98.44-2.12,1.33-3.43.89-1.31,2.26-2.45,4.13-3.43,1.86-.98,4.15-1.47,6.86-1.47,4.75,0,8.12,1.13,10.12,3.4s3,5.02,3,8.26v22.17c0,.44-.13.8-.4,1.07-.27.27-.62.4-1.07.4h-1.13c-.44,0-.8-.13-1.07-.4-.27-.27-.4-.62-.4-1.07v-3.13Zm0-13.25l-8.59,1.2c-3.64.49-6.39,1.33-8.26,2.53-1.86,1.2-2.8,2.71-2.8,4.53,0,2.04.85,3.62,2.56,4.73,1.71,1.11,3.69,1.66,5.96,1.66,3.24,0,5.9-1.07,7.99-3.2,2.09-2.13,3.13-5.19,3.13-9.19v-2.26Z"/>
          <path className="cls-2" fill={textColor} d="m296.33,79.55c-1.44-1.55-2.16-3.53-2.16-5.93,0-1.64.45-3.19,1.36-4.66.91-1.46,2.26-2.66,4.06-3.59s3.96-1.4,6.49-1.4c2.66,0,4.92.46,6.76,1.37,1.84.91,3.22,1.96,4.13,3.16.91,1.2,1.36,2.18,1.36,2.93,0,.4-.15.72-.43.97-.29.24-.63.37-1.03.37h-1c-.62,0-1.11-.29-1.46-.87-.89-1.33-1.9-2.34-3.03-3.03-1.13-.69-2.9-1.03-5.29-1.03-2.57,0-4.53.53-5.86,1.6-1.33,1.07-2,2.46-2,4.19,0,1.07.27,1.95.8,2.66.53.71,1.53,1.36,3,1.96,1.46.6,3.59,1.21,6.39,1.83,4.13.93,7.03,2.17,8.72,3.73,1.69,1.55,2.53,3.62,2.53,6.19,0,1.82-.51,3.48-1.53,4.99-1.02,1.51-2.53,2.71-4.53,3.59-2,.89-4.39,1.33-7.19,1.33s-5.32-.49-7.29-1.47c-1.98-.98-3.46-2.09-4.46-3.33-1-1.24-1.5-2.24-1.5-3,0-.4.16-.72.47-.97.31-.24.64-.37,1-.37h1c.31,0,.57.06.77.17.2.11.43.35.7.7.98,1.38,2.16,2.45,3.56,3.23,1.4.78,3.32,1.17,5.76,1.17,2.75,0,4.97-.52,6.66-1.56,1.69-1.04,2.53-2.54,2.53-4.49,0-1.2-.34-2.17-1.03-2.93-.69-.75-1.8-1.41-3.33-1.96-1.53-.55-3.81-1.19-6.82-1.9-3.95-.89-6.65-2.11-8.09-3.66Z"/>
          <path className="cls-2" fill={textColor} d="m351.85,68.57c2.6,3.06,3.89,7.26,3.89,12.58v1c0,.44-.14.8-.43,1.07-.29.27-.66.4-1.1.4h-23.7v.67c.09,2.09.57,4.03,1.43,5.83s2.07,3.24,3.63,4.33c1.55,1.09,3.37,1.63,5.46,1.63,2.44,0,4.43-.48,5.96-1.43,1.53-.95,2.63-1.92,3.29-2.9.4-.53.7-.87.9-1s.57-.2,1.1-.2h1.07c.4,0,.73.11,1,.33.27.22.4.51.4.87,0,.93-.59,2.05-1.76,3.36-1.18,1.31-2.81,2.44-4.89,3.4-2.09.95-4.44,1.43-7.06,1.43-4.22,0-7.61-1.39-10.19-4.16-2.57-2.77-4.04-6.49-4.39-11.15l-.07-2.66.07-2.66c.35-4.62,1.81-8.32,4.36-11.12,2.55-2.8,5.96-4.19,10.22-4.19,4.62,0,8.22,1.53,10.82,4.59Zm-.17,11.32v-.27c0-3.42-.97-6.24-2.9-8.46-1.93-2.22-4.52-3.33-7.76-3.33s-5.8,1.11-7.69,3.33c-1.89,2.22-2.83,5.04-2.83,8.46v.27h21.17Z"/>
        </g>
        <path className="cls-1" fill={shapeColor} d="m28.38,99.83c-15.13-.45-26.96-13.28-26.96-28.41V24.39c0-.34.13-.67.36-.92L21.07,2.56c.84-.91,2.37-.32,2.37.92v48.54c0,13.05,10.3,24.09,23.35,24.3,2.03.03,4-.19,5.89-.64.18-1.19.28-2.4.28-3.64V26.01c0-.75.61-1.36,1.36-1.36h1.33c.75,0,1.36.61,1.36,1.36v46.04c0,.74-.03,1.46-.09,2.18,8.24-3.73,13.99-12.03,13.99-21.64V6.55c0-.75.61-1.36,1.36-1.36h1.33c.75,0,1.36.61,1.36,1.36v46.03c0,12.19-7.88,22.56-18.82,26.31-3.12,12.28-14.45,21.33-27.78,20.94ZM5.47,25.44v46.61c0,13.09,10.65,23.74,23.74,23.74,10.29,0,19.07-6.58,22.36-15.75-1.43.23-2.9.35-4.39.35-15.33,0-27.8-12.47-27.8-27.8V10.37l-13.91,15.07Z"/>
      </svg>
    </div>
  )
}

export default Logo;