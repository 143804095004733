import { useEffect, useState } from "react";

const sendTracking = async (orderId) => {
	const url = process.env.REACT_APP_TRACKING_URL || false;
	if (!url) return;

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			orderId: orderId
		}),
	}).then((response) => response.json());

	console.log(response);
};

//creating function to load ip address from the API
const getData = async (order) => {
	sendTracking(order)
};

const Tracking = (props) => {
	const { order } = props;

	useEffect(() => {
		getData(order);
	}, [order]);

	return null;
};

export default Tracking;
