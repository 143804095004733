import React from 'react'
import './style.scss'

const LoaderBar = ({ size = 50 }) => {
  return (
    <div className="loader-bar" style={{ width: size, height: size * 0.5 }}>
      <div className="loader-bar-inner" style={{ height: size * 0.8 }}>
        <span className={`icon--loader_bar`}></span>
      </div>
    </div>
  )
}

export default LoaderBar
