import { useEffect, useRef, useState } from "react";
import "./Video.scss";

const Video = () => {
	const videoEmbed = useRef();
	const [play, togglePlay] = useState(false);
	const playButton = useRef();

	const playHandler = () => {

    if (videoEmbed && videoEmbed.current) {
			if ( !play ) {
				!!videoEmbed.current && videoEmbed.current.play();
			} else {
				!!videoEmbed.current && videoEmbed.current.pause();
			}
    }
		togglePlay(!play)
	}

	useEffect(() => {
		const triggerPlay = () => {
			if ( !videoEmbed ) return;
			let videoEmbedHeight = videoEmbed.current.offsetHeight;
			let videoEmbedTop = videoEmbed.current.getBoundingClientRect().top;
			let videoEmbedBottom = videoEmbed.current.getBoundingClientRect().bottom;
			let videoEmbedHalf = videoEmbedHeight / 2;
			let videoEmbedMiddle = videoEmbedTop + videoEmbedHalf;
			let videoEmbedMiddleBottom = videoEmbedBottom - videoEmbedHalf;
			let windowMiddle = window.innerHeight / 2;
			let windowTop = window.innerHeight;
			let windowBottom = 0;
			let windowMiddleBottom = windowTop - windowMiddle;
			let windowMiddleTop = windowMiddle;
			let videoEmbedInScreen = false;
			if ( videoEmbedMiddle >= windowMiddleBottom && videoEmbedMiddle <= windowMiddleTop ) {
				videoEmbedInScreen = true;
			}
			if ( videoEmbedMiddleBottom >= windowMiddleBottom && videoEmbedMiddleBottom <= windowMiddleTop ) {
				videoEmbedInScreen = true;
			}
			if ( videoEmbedTop >= windowBottom && videoEmbedTop <= windowTop ) {
				videoEmbedInScreen = true;
			}
			if ( videoEmbedBottom >= windowBottom && videoEmbedBottom <= windowTop ) {
				videoEmbedInScreen = true;
			}
			try {
				if ( videoEmbedInScreen ) {
					!!videoEmbed.current && videoEmbed.current.play();
					togglePlay(true);
				} else {
					togglePlay(false);
					!!videoEmbed.current && videoEmbed.current.pause();
				}
			} catch (e) {
				console.log(e)
			}
		}
		const autoRunVideo = () => {
			!!window.unkbVideoChecker && clearTimeout(window.unkbVideoChecker);
			window.unkbVideoChecker = setTimeout(triggerPlay, 100);	
		}
		window.addEventListener('scroll', autoRunVideo);
		//Dispatch user's click event at position 0,0
		window.document.dispatchEvent(new Event('click'));

		return () => {
			window.removeEventListener('scroll', autoRunVideo);
		}
	}, []);



	return(
		<div className={`video__player ${play ? 'playing' : ''}`}>
			<video 
				autoPlay={true}
				loop={true}
				muted="muted"
				playsInline={true}
				webkit-playsinline="true"
				ref={videoEmbed} 
				poster={require("../../../assets/wallet-unikbase-laura.png")} width="100%" 
				height="100%"
			>
				<source src={require("../../../assets/videos/video.mov")} type="video/mp4" />
			</video>
			{/* <span ref={playButton} onClickCapture={playHandler} className="play-button"><span className="arrow"></span></span> */}
			<div className="video__holder">
			</div>
		</div>
	)
}

export default Video;