import Typography from "@mui/material/Typography";
// import Link from '@mui/material/Link';
import Container from "@mui/material/Container";
import Page from "../../layout/Page";
import { t } from "i18next";

const SuccessPage = (props) => {

	return (
		<Page>
				<Container
					disableGutters
					maxWidth="lg"
					component="section"
					className="section bg--blue"
				>
					<div className="row">
						<div className="col col--1">

							<Typography
								component="h2"
								variant="h4"
								align="center"
								gutterBottom
								sx={{ fontWeight: 500, mb: "1rem", pb: "0rem" }}
							>
								<span
									style={{
										textDecoration: "underline",
										textDecorationColor: "var(--unikbase-red)",
									}}
								>
									{t('pages.success_page.title')}
								</span>
							</Typography>
							<Typography
								variant="h4"
								align="center"
								component="p"
								sx={{
									mt: "1em",
									mb: "1em",
									fontWeight: 500,
								}}
							>
								{t('pages.success_page.sub_title')}
							</Typography>

							<Typography
								component="div"
								variant="p"
								align="center"
								sx={{ mb: "1em", fontSize: "20px", fontWeight: 400 }}
							>
								<div dangerouslySetInnerHTML={{ __html: t('pages.success_page.description') }}></div>
							</Typography>

							{t('pages.success_page.email') && (
								<Typography
									variant="p"
									align="center"
									component="div"
									sx={{ mb: "1em", fontSize: "20px", fontWeight: 400 }}
								>
									{t('pages.success_page.email_text')}{" "}
									<a
										style={{ color: "#fff", textDecorationColor: "#EA5123", textDecoration: "underline" }}
										href={`mailto:${t('pages.success_page.email')}`}
										target="_blank"
										rel="noreferrer"
										title={`Email to ${t('pages.success_page.email')}`}
									>
										{t('pages.success_page.email')}
									</a>
								</Typography>
							)}
						</div>
					</div>
				</Container>
		</Page>
	);
};

export default SuccessPage;
