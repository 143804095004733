import { useState } from 'react'
import { Typography, Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid, Box, OutlinedInput } from "@mui/material";
import { t } from "i18next";
import { digestMessage } from '../../page/LandingPage/LandingPage';

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const computeEmailHash = async (email: string) => {
  return await digestMessage(email, 'SHA-1')
}

interface IEmailRequiredModal {
  hash: string;
  open: boolean;
  onUpdate: (email: string) => void;
}

const EmailRequiredModal = ({ hash, open, onUpdate }: IEmailRequiredModal) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const _handleClose = () => {
    // keep the modal open
  }

  const onChange = (evt: any) => {
    setError('');
    setEmail(evt.target.value);
  }

  const onSubmit = async () => {
    if (emailRegex.test(email) === false) {
      return setError(t('pages.landing_page.email_invalid'));
    }
    const _hash = await computeEmailHash(email);
    if (hash !== _hash) {
      return setError(t('pages.landing_page.email_hash_incorrect'));
    }
    setError('');
    onUpdate(email);
  }

  return (
    <Dialog
      open={open}
      onClose={_handleClose}
    >
      <DialogTitle>{t('pages.landing_page.email_is_required')}</DialogTitle>
      <DialogContent>
        <Typography>{t('pages.landing_page.enter_artsper_email')}</Typography>
        <Box display="flex" flexDirection="column" mt={2}>
          <OutlinedInput
            key={`email-input`}
            id="email"
            notched={false}
            placeholder={'john@abc.com'}
            onChange={onChange}
            error={!!error}
          />
          {!!error && <Typography variant='body2' component='span' mt={1} color="error">{error}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container p={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              variant="contained"
              size='large'
              style={{ width: '100%' }}
              onClick={onSubmit}>
              {t('texts.continue')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EmailRequiredModal
